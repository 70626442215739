import {Container, Text, Flex, Grid, ButtonProps} from '@chakra-ui/react';

import {py} from '@eksab/theme';
import {useTranslate} from '@eksab/i18n';
import {HomeIcon, NotFoundIcon} from '@eksab/assets/icons';
import {StickyActions} from '@eksab/features/store/badges/components';
import NextLink from 'next/link';
import Head from 'next/head';
import {GetStaticProps} from 'next';
import {dehydrate, QueryClient} from '@tanstack/react-query';
import {setApiLocale} from '@eksab/api';
import {useRegionSettings} from '@eksab/hooks/useRegionSettings';

export default function NotFound() {
  const t = useTranslate();

  return (
    <>
      <Head>
        <title>{`${t('app.errors.notfound.heading')} | ${t('app.name')}`}</title>
      </Head>

      <Container flexDir="column" alignItems="center" py={py} flex={{base: 'initial', lg: 1}}>
        <Grid
          flex={1}
          alignItems="center"
          templateColumns="1fr"
          justifyItems="center"
          rowGap="4"
          columnGap={{base: 12, xl: 24}}
        >
          <NotFoundIcon width={{base: '264px', lg: '485px'}} height="auto" />
          <Flex
            flexDir="column"
            gap={{base: 1.5, lg: 3}}
            textAlign={{base: 'center', lg: 'start'}}
            gridRow={{lg: '1/2'}}
            gridColumn={{lg: 3}}
            alignSelf="center"
            alignItems={{base: 'center', lg: 'start'}}
          >
            <Text fontSize={{base: '2xl', lg: '4xl'}} fontWeight="700">
              {t('app.errors.notfound.heading')}
            </Text>

            <Text fontSize={{base: 'sm', lg: 'md'}} fontWeight="500" color="#191b20" mb="10">
              {t('app.errors.notfound.body')}
            </Text>
            <Flex display={{base: 'none', lg: 'flex'}} justifyContent="center">
              <CTA />
            </Flex>
          </Flex>
        </Grid>
      </Container>

      <StickyActions
        display={{base: 'flex', lg: 'none'}}
        justifyContent="center"
        minH="9.25rem"
        shadow="none"
        rounded-top="none"
      >
        <CTA />
      </StickyActions>
    </>
  );
}

const CTA = (props: ButtonProps) => {
  const t = useTranslate();

  return (
    <NextLink href="/" passHref legacyBehavior>
      <StickyActions.CTA as="a" gap={[6, null, 10]} ms="unset" {...props}>
        <Text color="inherit">{t('app.home')}</Text>
        <HomeIcon color="white" />
      </StickyActions.CTA>
    </NextLink>
  );
};

export const getStaticProps: GetStaticProps = async ({locale}) => {
  const queryClient = new QueryClient();

  setApiLocale(locale);

  await queryClient.prefetchQuery([useRegionSettings.queryKey, locale], useRegionSettings.queryFn);

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 60 * 60 * 3,
  };
};
